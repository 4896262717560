import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import * as Sentry from "@sentry/react";

import modalReducer from "./modal/slice";
import playerReducer from "./player/slice";
import promptsReducer from "./prompts/slice";
import tableReducer from "./table/slice";
import twilioReducer from "./twilio/slice";
import localPlayerReducer from "./localPlayer/slice";
import gameReducer from "./gameInstance/slice";
import bettingReducer from "./betting/slice";
import cardSelectionReducer from "./cardSelection/slice";
import positionHighlightReducer from "./positionHighlight/slice";
import connectionReducer from "./connection/slice";

const appReducer = combineReducers({
  connection: connectionReducer,
  modal: modalReducer,
  localPlayer: localPlayerReducer,
  twilio: twilioReducer,
  betting: bettingReducer,
  cardSelection: cardSelectionReducer,
  positionHighlight: positionHighlightReducer,
  table: tableReducer,
  gameInstance: gameReducer,
  prompt: promptsReducer,
  player: playerReducer,
});

export const RESET_STATE = { type: "RESET" };

const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (action.type === RESET_STATE.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

// TODO(@Nerdsie): Manage more specifically, don't just save and load complete redux state
export function makeStore(name: string) {
  return configureStore({
    reducer: rootReducer,
    devTools: {
      trace: true,
      traceLimit: 25,
      name: name,
    },
    // For now keep the LogRocket middleware out of the picture to reduce the amount of data sent to LogRocket
    // if we decide to enable it add "".concat(LogRocket.reduxMiddleware())" to he line below
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
  });
}

const store = makeStore("Default");

export type AppDispatch = typeof store.dispatch;

export default store;
