/**
 * ActionArea.tsx
 *
 * This component is used when player/host goes inside a table and calls all the respective components based on redux store value
 */
import React, { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { BetArea } from "./BetArea";
import { AlertArea } from "./AlertArea";
import { StartArea } from "./StartArea";
import { EndShowdownArea } from "./EndShowdownArea";
import { WaitingArea } from "./WaitingArea";
import { WaitingNextGame } from "./WaitingNextGame";
import { BuyLastCardUpArea } from "./BuyLastCardUpArea";
import { ExtraActionArea } from "./ExtraActionArea/ExtraActionArea";
import { ChooseCardArea } from "./CardSelection/ChooseCardArea";
import { AnteArea } from "./AnteArea";
import { DeclareArea } from "./DeclareArea";
import { DeclareInOutArea } from "./DeclareInOutArea";
import { OfferCardArea } from "./OfferCardArea";
import { DealArea } from "./DealArea/DealArea";
import { Lobby } from "./Lobby";
import { Seat } from "../Seat/Seat";
import { PlayerHand } from "../Hand";
import { HandValue } from "./HandValue";
import { PlayerBet } from "../PlayerBet";
import { OutOfCards } from "./OutOfCards";
import { MasterStartup } from "./MasterStartup";
import { PreShutdownArea } from "./PreShutdownArea";
import { TableContext } from "../../views/table/Table";
import { LobbyTableCode } from "./Components/LobbyTableCode";
import { ErrorBoundary } from "../../../error_handling/ErrorBoundary";
import GameTypeList from "../SelectGame/GameTypeList";
import { Game, TableStatus } from "poker-cows-common";
import { useSeatContext } from "../Seat/SeatContext";
import { useCurrentPlayer } from "../../../logic/gameInstance/hooks";
import { selectPlayerAtTablePos } from "../../../logic/player/slice";
import { selectLocalPlayerId } from "../../../logic/localPlayer/slice";
import { selectRemainingPlayers } from "../../../logic/gameInstance/slice";
import {
  selectGameType,
  selectIsBotTable,
  selectIsGamePaused,
  selectIsTableInPreShutdown,
  selectTableStatus,
} from "../../../logic/table/slice";
import {
  selectAlertPrompt,
  selectAntePrompt,
  selectAnyDelayPrompt,
  selectTexasDelayPrompt,
  selectBetPrompt,
  selectChooseGamePrompt,
  selectDeclarePrompt,
  selectDelayOfGamePrompt,
  selectAnyCardSelectionPrompt,
  selectPlayerShowdownPrompt,
  selectStartPrompt,
  submitTexasDelayPrompt,
  selectOfferCardPrompt,
  selectBuyLastCardUpPrompt,
  selectInitializeTablePrompt,
  selectStartNormalGamePlayPrompt,
  selectOutOfCardsPrompt,
  selectShortDelayPrompt,
  shortDelayAction,
  selectAnyDealerPrompt,
  selectAnyChooseGamePrompt,
  selectAnyDealPrompt,
  selectDeclareInOutPrompt,
  selectAnyMultiplayerPrompt,
  selectMultiplayerPrompt,
  playAutomaticallyForBot,
  submitPlaySoundPrompt,
  selectPlaySoundPrompt,
  selectLowChipPrompt,
} from "../../../logic/prompts/slice";
import { randomNumberBetweenRange } from "../../../utils/Randomize";
import { WAITING_TEXT } from "../../../test-identifiers";
import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";
import "./ActionArea.css";
import { playSound } from "../../../utils/Sound";

const DEFAULT_LENGTH_OF_DELAY = 1250;

const TakingABreakContainer = (
  <div data-testid={WAITING_TEXT}>You are currently taking a break.</div>
);

const WaitingCardsContainer = <div className="waitingText" />;
const WaitingNextGameContainer = <WaitingNextGame />;

export const ActionArea = () => {
  const dispatch = useDispatch();
  const { tablePosition } = useSeatContext();
  const { currentModalName, showModal } = useModal();
  const { currentPlayer, isCurrentPlayer } = useCurrentPlayer(tablePosition);
  const { player } = useSelector(selectPlayerAtTablePos(tablePosition));
  const eventCallback = useContext(TableContext).eventCallback;
  const { chooseGamePrompt } = useSelector(selectChooseGamePrompt);
  const { betPrompt } = useSelector(selectBetPrompt);
  const { alertPrompt } = useSelector(selectAlertPrompt);
  const { offerCardPrompt } = useSelector(selectOfferCardPrompt);
  const { outOfCardsPrompt } = useSelector(selectOutOfCardsPrompt);
  const { startPrompt } = useSelector(selectStartPrompt);
  const { playerShowdownPrompt } = useSelector(selectPlayerShowdownPrompt);
  const { anyCardSelectionPrompt } = useSelector(selectAnyCardSelectionPrompt);
  const { antePrompt } = useSelector(selectAntePrompt);
  const { declarePrompt } = useSelector(selectDeclarePrompt);
  const { declareInOutPrompt } = useSelector(selectDeclareInOutPrompt);
  const delayPrompt = !!useSelector(selectAnyDelayPrompt).anyDelayPrompt;
  const { isGamePaused } = useSelector(selectIsGamePaused);
  const { isTableInPreShutdown } = useSelector(selectIsTableInPreShutdown);
  const { texasDelayPrompt } = useSelector(selectTexasDelayPrompt);
  const { anyDealPrompt } = useSelector(selectAnyDealPrompt);
  const { buyLastCardUpPrompt } = useSelector(selectBuyLastCardUpPrompt);
  const { initializeTablePrompt } = useSelector(selectInitializeTablePrompt);
  const { lowChipPrompt } = useSelector(selectLowChipPrompt);
  const { startNormalGamePlayPrompt } = useSelector(
    selectStartNormalGamePlayPrompt
  );
  const { delayOfGamePrompt } = useSelector(selectDelayOfGamePrompt);
  const shortDelayPromptId = useSelector(selectShortDelayPrompt)
    .shortDelayPrompt?.id;
  const { anyDealerPrompt } = useSelector(selectAnyDealerPrompt);
  const { anyChooseGamePrompt } = useSelector(selectAnyChooseGamePrompt);
  const { multiplayerPrompt } = useSelector(selectMultiplayerPrompt);
  const { anyMultiplayerPrompt } = useSelector(selectAnyMultiplayerPrompt);
  const multiplayerTurnIndicatorForOtherPlayers =
    !multiplayerPrompt && !!anyMultiplayerPrompt;

  const { playSoundPrompt } = useSelector(selectPlaySoundPrompt);
  const playSoundPromptSoundId = playSoundPrompt?.data?.soundId;

  const { isBotTable } = useSelector(selectIsBotTable);
  const { tableStatus } = useSelector(selectTableStatus);
  const { localPlayerId } = useSelector(selectLocalPlayerId);
  const { remainingPlayers } = useSelector(selectRemainingPlayers);
  const { gameType } = useSelector(selectGameType);

  // TODO NICHOLAS LOBBY-PAGE
  // this dispatching of the prompt should
  // end up being in whatever Lobby parent component handles the lobby pages
  useEffect(() => {
    if (texasDelayPrompt && !anyDealPrompt) {
      dispatch(submitTexasDelayPrompt());
    }
  }, [texasDelayPrompt, anyDealPrompt, dispatch]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const WaitingContainer = useMemo(() => <WaitingArea />, [currentPlayer]);

  let visibleArea = WaitingContainer;

  if (delayPrompt) {
    visibleArea = WaitingNextGameContainer;
  }
  if (delayOfGamePrompt) {
    visibleArea = <EndShowdownArea />;
  }
  if (offerCardPrompt) {
    visibleArea = <OfferCardArea />;
  }
  if (outOfCardsPrompt) {
    visibleArea = <OutOfCards />;
  }
  if (antePrompt) {
    visibleArea = <AnteArea />;
  }
  if (betPrompt) {
    visibleArea = <BetArea />;
  }
  if (initializeTablePrompt) {
    visibleArea = <MasterStartup />;
  }
  if (startNormalGamePlayPrompt) {
    visibleArea = <Lobby />;
  }
  if (chooseGamePrompt && !playerShowdownPrompt) {
    visibleArea = <GameTypeList />;
  }
  if (startPrompt && !playerShowdownPrompt) {
    visibleArea = <StartArea />;
  }
  if (anyCardSelectionPrompt) {
    visibleArea = <ChooseCardArea />;
  }
  if (declarePrompt) {
    visibleArea = <DeclareArea />;
  }
  if (declareInOutPrompt) {
    visibleArea = <DeclareInOutArea />;
  }
  if (anyDealPrompt) {
    visibleArea = <DealArea />;
  }
  if (buyLastCardUpPrompt) {
    visibleArea = <BuyLastCardUpArea />;
  }
  if (isGamePaused) {
    visibleArea = <div className="waitingText">Host has paused the game</div>;
  }
  if (isTableInPreShutdown || tableStatus === TableStatus.POST_GAME) {
    visibleArea = <PreShutdownArea />;
  }
  if (shortDelayPromptId) {
    visibleArea = <></>;
  }
  if (alertPrompt) {
    visibleArea = <AlertArea />;
  }
  if (lowChipPrompt) {
    visibleArea = <></>;
  }
  if (player?.takingBreak) {
    visibleArea = TakingABreakContainer;
  }

  const isWaiting = useMemo(
    () =>
      visibleArea === WaitingContainer ||
      visibleArea === WaitingCardsContainer ||
      visibleArea === WaitingNextGameContainer,
    [visibleArea, WaitingContainer]
  );

  const inLobby = tableStatus === "IN_LOBBY";

  useEffect(() => {
    if (isWaiting) {
      eventCallback("waiting");
    } else {
      eventCallback("active");
    }
  }, [eventCallback, isWaiting]);

  // For guts game, when playing with bots, if a bot auto purchased chips then notify the player with a modal
  // - show at a time of delayOfPrompt exist
  // - do not notify when the player has purchased chips
  useEffect(() => {
    if (
      isBotTable &&
      gameType === Game.Guts &&
      player &&
      delayPrompt &&
      currentModalName !== ModalName.LedgerModal
    ) {
      const newTransaction =
        player.bankingHistory[player.bankingHistory.length - 1];

      if (newTransaction.description.includes("chips_from_bank")) {
        showModal({
          name: ModalName.ChipPurchasedModal,
        });
      }
    }
  }, [player?.bankingHistory.length]);

  /**
   * Here we handle bot play when it's bots turn. The way this works is we check:
   *    - if the table is not bot table, we early exit
   *    - if the table is bot table but waiting on player, we early exit
   *    - if the table is bot table but game has ended, we early exit
   *    - if the player is waiting, we play automatically
   *    - if the bots are supposed to act on multiplayer prompt, we play automatically for all the bots that have not folded
   * NOTE: we set an artificial delay before triggering any prompt so that bots don't play too fast
   */
  useEffect(() => {
    if (!isBotTable || (isBotTable && (isCurrentPlayer || delayOfGamePrompt))) {
      return;
    }
    if (visibleArea === WaitingContainer) {
      const timer = setTimeout(() => {
        dispatch(playAutomaticallyForBot());
      }, DEFAULT_LENGTH_OF_DELAY * randomNumberBetweenRange(1, 2));
      return () => clearTimeout(timer);
    }
    if (
      multiplayerTurnIndicatorForOtherPlayers &&
      Object.keys(remainingPlayers).length !== 0
    ) {
      Object.keys(remainingPlayers).forEach((playerId) => {
        if (localPlayerId !== playerId && !delayOfGamePrompt) {
          const timer = setTimeout(() => {
            dispatch(playAutomaticallyForBot());
          }, DEFAULT_LENGTH_OF_DELAY * randomNumberBetweenRange(1, 2));
          return () => clearTimeout(timer);
        }
      });
    }
  }, [
    isBotTable,
    isCurrentPlayer,
    delayOfGamePrompt,
    visibleArea,
    WaitingContainer,
    localPlayerId,
    remainingPlayers,
    multiplayerTurnIndicatorForOtherPlayers,
    dispatch,
  ]);

  /* This creates a delay using the ShortDelayPrompt so that the last players
  bet or check action is displayed on the table before moving to the next round.
  Adjust the second parameter in SetTimeout (DEFAULT_LENGTH_OF_DELAY) to change the length of the delay. */
  useEffect(() => {
    if (shortDelayPromptId) {
      setTimeout(() => {
        dispatch(shortDelayAction());
      }, DEFAULT_LENGTH_OF_DELAY);
    }
  }, [shortDelayPromptId, dispatch]);

  useEffect(() => {
    if (playSoundPromptSoundId) {
      playSound(playSoundPromptSoundId);
      dispatch(submitPlaySoundPrompt());
    }
  }, [dispatch, playSoundPromptSoundId]);

  const isGameBeingSelected = anyChooseGamePrompt || anyDealerPrompt;

  // show modal of low chip
  useEffect(() => {
    if (lowChipPrompt?.id) {
      showModal({
        name: ModalName.LowChipModal,
      });
    }
  }, [lowChipPrompt?.id, showModal]);

  return (
    <div className="actionAreaWrapper">
      <div className="actionArea">
        {!isGameBeingSelected && (
          <ErrorBoundary>
            <div className="userCardBetColumn">
              <PlayerBet tablePosition={tablePosition} />
              <PlayerHand />
            </div>
          </ErrorBoundary>
        )}
        <ErrorBoundary>
          <HandValue tablePosition={tablePosition} />
        </ErrorBoundary>
        <ErrorBoundary>
          <div className="actionAreaContainer">
            {isWaiting && inLobby && <LobbyTableCode />}
            {
              <ExtraActionArea
                isDiscarding={!!anyCardSelectionPrompt}
                isWaiting={isWaiting}
                isBetting={!!betPrompt}
              />
            }
            <Seat key={7} />
            <div className="visibleActionArea">{visibleArea}</div>
          </div>
        </ErrorBoundary>
      </div>
    </div>
  );
};
