import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { StateIdType, TableInterface } from "poker-cows-common";
import { RootState } from "../store";
import { updateTable } from "../shared";
import { createTable, resetGame } from "./thunks";

// =============================== //
//          Initial State          //
// ================================//
export const initialState = {} as TableInterface;

// =============================== //
//              Slice              //
// ================================//
export const slice = createSlice({
  name: "table",
  initialState: initialState,
  reducers: {
    setTableId: (state, action: PayloadAction<StateIdType>) => {
      state.id = action.payload ?? 0;
    },
    setTableGroup: (state, action: PayloadAction<string>) => {
      return { ...state, tableGroup: action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createTable.fulfilled, (state, action) => {
      return { ...state, ...action.payload.table };
    });
    builder.addCase(resetGame.fulfilled, (state, action) => {
      return { ...state, ...action.payload };
    });
    builder.addCase(
      updateTable,
      (state, action: { type: string; payload: { table: TableInterface } }) => {
        const table = action.payload.table;

        if (table) {
          return { ...state, ...table };
        }

        return initialState;
      }
    );
  },
});

// =============================== //
//            Actions              //
// ================================//
export const { setTableId, setTableGroup } = slice.actions;

// =============================== //
//            Reducers             //
// ================================//
export default slice.reducer;

// =============================== //
//            Selectors            //
// ================================//
const selectTable = (state: RootState) => state.table;
const selectLocalPlayer = (state: RootState) => state.localPlayer;
const selectTablePlayers = (state: RootState) => state.player.players ?? [];
export const selectPokerSessionId = createSelector(
  selectTable,
  ({ pokerSessionId }) => ({ pokerSessionId })
);
export const selectTableId = createSelector(selectTable, ({ id }) => ({
  tableId: id ?? 0,
}));
export const selectTableCode = createSelector(selectTable, ({ code }) => ({
  tableCode: code ?? "",
}));
export const selectTableGroup = createSelector(
  selectTable,
  ({ tableGroup }) => ({ tableGroup: tableGroup ?? "" })
);
export const selectIsBotTable = createSelector(
  selectTable,
  ({ isBotTable }) => ({
    isBotTable: !!isBotTable,
  })
);
export const selectTableStatus = createSelector(selectTable, ({ status }) => ({
  tableStatus: status ?? "",
}));
export const selectGameType = createSelector(selectTable, ({ gameType }) => ({
  gameType,
}));
export const selectNextPrefs = createSelector(selectTable, ({ nextPrefs }) => ({
  nextPrefs,
}));
export const selectHostId = createSelector(selectTable, ({ hostId }) => ({
  hostId,
}));
export const selectHost = createSelector(
  [selectHostId, selectTablePlayers],
  ({ hostId }, players) => ({
    host: players.find((player) => player.id === hostId),
  })
);
export const selectIsLocalPlayerHost = createSelector(
  [selectLocalPlayer, selectHostId],
  (localPlayer, { hostId }) => ({
    isLocalPlayerHost: localPlayer?.id && hostId && localPlayer.id === hostId,
  })
);
export const selectCurrentDealerId = createSelector(
  selectTable,
  ({ currentDealerId }) => ({ currentDealerId })
);
export const selectCurrentDealer = createSelector(
  [selectCurrentDealerId, selectTablePlayers],
  ({ currentDealerId }, players) => ({
    currentDealer: players.find((player) => player.id === currentDealerId),
  })
);
export const selectIsLocalPlayerDealer = createSelector(
  [selectLocalPlayer, selectCurrentDealerId],
  (localPlayer, { currentDealerId }) => ({
    isLocalPlayerDealer:
      localPlayer?.id && currentDealerId && localPlayer.id === currentDealerId,
  })
);
export const selectIsGamePaused = createSelector(selectTable, ({ paused }) => ({
  isGamePaused: paused ?? false,
}));
export const selectIsNoLimitFullRound = createSelector(
  selectTable,
  ({ noLimitFullRound }) => ({ isNoLimitFullRound: noLimitFullRound })
);
export const selectIsPreviousGameContinued = createSelector(
  selectTable,
  ({ toContinueSameGame }) => ({ isPreviousGameContinued: toContinueSameGame })
);
export const selectIsTableInPreShutdown = createSelector(
  selectTable,
  ({ preShutdown }) => ({ isTableInPreShutdown: preShutdown ?? false })
);
export const selectTableSessionEndTime = createSelector(
  selectTable,
  ({ sessionEndTime }) => ({ tableSessionEndTime: sessionEndTime })
);
export const selectIsSessionEndTimeDismissible = createSelector(
  selectTable,
  ({ isSessionEndTimeDismissible }) => ({ isSessionEndTimeDismissible })
);
export const selectWhenToArchiveTable = createSelector(
  selectTable,
  ({ whenToArchive }) => ({ archiveTableTime: whenToArchive })
);
