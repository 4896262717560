/**
 * StartNewTable.tsx
 * This component used to create table from a host
 */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { createTable } from "../../../logic/table/thunks";
import { selectTableCode } from "../../../logic/table/slice";
import { SessionKeys, setSession } from "../../../utils/SessionSettings";
import { useModal } from "../../components/Modal/hooks/useModal";
import { ModalName } from "../../components/Modal/ModalConfig";
import { getLocalPlayer } from "../../../logic/localPlayer/thunks";
import {
  selectLocalPlayerEmail,
  selectLocalPlayerFreeTrialStatus,
} from "../../../logic/localPlayer/slice";

export const StartNewTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { tableCode } = useSelector(selectTableCode);
  const { freeTrialStatus } = useSelector(selectLocalPlayerFreeTrialStatus);
  const { localPlayerEmail } = useSelector(selectLocalPlayerEmail);

  useEffect(() => {
    dispatch(getLocalPlayer());
  }, [dispatch, getLocalPlayer]);

  // create table after received details of the local player
  useEffect(() => {
    if (localPlayerEmail) {
      setSession(SessionKeys.isBotTable, "false");
      setSession(SessionKeys.isLocalPlayerHost, "true");

      dispatch(createTable({ name: "Marketing Table" }));
    }
  }, [dispatch, localPlayerEmail]);

  // trigger detail when we have table code
  useEffect(() => {
    if (freeTrialStatus?.started) {
      history.push(`table/${tableCode}`);
      return;
    }

    if (tableCode) {
      showModal({
        name: ModalName.WelcomeModal,
        requiresBackendVerification: false,
      });
    }
  }, [tableCode]);

  return <Loading />;
};
