/**
 * StartArea.tsx
 *
 * This is mostly a central part of StartAGameModal which has custom header and setting and rules for the selected game
 */
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faSquare } from "@fortawesome/free-solid-svg-icons";
import {
  startGame,
  dealerChangePrefs,
  undoGameSelection,
} from "../../../logic/prompts/slice";
import { GameSelectHeader } from "../SelectGame/GameSelectHeader";
import {
  selectGameType,
  selectNextPrefs,
  selectIsPreviousGameContinued,
} from "../../../logic/table/slice";
import { selectAreAnyPlayersBanking } from "../../../logic/player/slice";
import {
  getCardImages,
  getCardRules,
  getGameOptions,
  getGameRules,
  getPrettyGameType,
} from "../../../utils/GameInfo";
import { Tooltip } from "../Tooltip/Tooltip";
import { Settings } from "../Settings/Settings";
import { PRACTICE_BUTTON, START_GAME_BUTTON } from "../../../test-identifiers";
import Button from "../Button";
import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import { BetLimit, ForcedBet } from "poker-cows-common";
import { GameVersionInterface } from "poker-cows-common";
import "./StartArea.css";
import { GameFlavor } from "../Settings/RulesPages/GameFlavor";

export const StartAreaContents = (props: ModalProps) => {
  const dispatch = useDispatch();
  const { gameType } = useSelector(selectGameType);
  const { nextPrefs } = useSelector(selectNextPrefs);

  const gameTitle = getPrettyGameType(gameType);
  const gameRules = getGameRules(gameType);
  const gameOptions = getGameOptions(gameType);
  const playerHandImage = getCardImages(gameRules, nextPrefs?.gameFlavor);
  const versions = gameOptions?.versions;

  const togglePracticeMode = () => {
    const isPracticeMode = nextPrefs?.practiceMode === true;
    const previousPracticeRoundNumber = nextPrefs?.practiceRoundNumber ?? 0;
    const practiceRoundNumber = isPracticeMode
      ? previousPracticeRoundNumber - 1
      : previousPracticeRoundNumber + 1;
    const prefsString = JSON.stringify({
      practiceMode: !nextPrefs?.practiceMode,
      practiceRoundNumber: practiceRoundNumber,
    }).replace(" ", "");
    dispatch(dealerChangePrefs(prefsString));
  };

  const start = useCallback(() => {
    dispatch(startGame());
  }, [dispatch]);

  const changeGameFlavor = (versionIndex: number) => {
    const selectedVersion = versions?.[versionIndex];
    const gameFlavor = selectedVersion?.flavor;
    const limitType = selectedVersion?.betLimit;
    const noLimit = limitType === BetLimit.NO_LIMIT;
    const forcedBetType = noLimit ? ForcedBet.BLINDS : ForcedBet.ANTES;
    const allowAllIn = !!noLimit;

    const prefsString = JSON.stringify({
      gameFlavor,
      limitType,
      forcedBetType,
      allowAllIn,
    });
    dispatch(dealerChangePrefs(prefsString));
  };

  const undoGame = () => {
    dispatch(undoGameSelection());
  };

  const getGameVersion = () => {
    const gameFlavor = nextPrefs?.gameFlavor;
    const selectedVersionIndex =
      versions?.findIndex(
        (version: GameVersionInterface) =>
          version.flavor === nextPrefs?.gameFlavor &&
          version.betLimit === nextPrefs?.limitType
      ) ?? -1;

    if (
      selectedVersionIndex > -1 &&
      Array.isArray(versions) &&
      versions.length > 1
    ) {
      return versions.map((version, index) => (
        <GameFlavor
          key={index}
          isFlavorSelected={selectedVersionIndex == index}
          value={index}
          title={
            getCardRules(gameRules, version.flavor)?.join(" | ") ??
            version.description
          }
          changeGameFlavor={changeGameFlavor}
        />
      ));
    }

    return (
      <span className="cardRule">
        {getCardRules(gameRules, gameFlavor)?.join(" | ")}
      </span>
    );
  };

  const getGameNameAndCardInfo = () => {
    return (
      <>
        <span>{gameTitle.toString()}</span>
        <br />
        <div className="cardInfo radiobox">{getGameVersion()}</div>
      </>
    );
  };

  return (
    <div className="startAreaContentsWrapper">
      <GameSelectHeader
        onBack={() => undoGame()}
        header={getGameNameAndCardInfo()}
        headerImage={playerHandImage}
      />
      <Settings rulesFirst={false} />
      <footer className="modalFooter">
        <div className="buttonsStartGame">
          <div className="practiceModeCheckboxPosition">
            <div className="practiceModeCheckboxContainer">
              <div
                data-testid={PRACTICE_BUTTON}
                className="practiceModeClickWrapper"
                onClick={togglePracticeMode}
              >
                <span className="practiceModeCheckbox">
                  {nextPrefs?.practiceMode && (
                    <FontAwesomeIcon
                      className="checked"
                      icon={faCheckSquare as IconProp}
                    />
                  )}
                  {!nextPrefs?.practiceMode && (
                    <FontAwesomeIcon
                      className="unChecked"
                      icon={faSquare as IconProp}
                    />
                  )}
                </span>
                <div className="practiceModeCheckboxText">
                  Practice Mode&nbsp;
                </div>
              </div>
              <Tooltip
                title="Start Game in Practice Mode"
                body="Money returned to each player at the end of the hand."
              />
            </div>
          </div>
          <Button
            data-testid={START_GAME_BUTTON}
            className="modalButtonPrimary"
            onClick={start}
          >
            START GAME
          </Button>
        </div>
      </footer>
    </div>
  );
};

// displayed to dealers when continuing previous game
const ContinueGameArea = () => {
  const dispatch = useDispatch();

  const { areAnyPlayersBanking } = useSelector(selectAreAnyPlayersBanking);

  const start = useCallback(() => {
    dispatch(startGame());
  }, [dispatch]);

  const undoGame = () => {
    dispatch(undoGameSelection());
  };

  const DealerPromptText = () => {
    if (areAnyPlayersBanking) {
      return (
        <span>
          Waiting for players to
          <br /> complete buying chips
        </span>
      );
    }

    return (
      <span>
        IT'S YOUR DEAL.
        <br /> WHEN READY...
      </span>
    );
  };

  const ChangeGameButton = () => (
    <Button
      variant="base"
      className="actionButton"
      onClick={undoGame}
      disabled={areAnyPlayersBanking}
    >
      Change Game
    </Button>
  );
  const DealTheCardsButton = () => (
    <Button
      data-testid={START_GAME_BUTTON}
      className="actionButton green"
      onClick={start}
      disabled={areAnyPlayersBanking}
    >
      Deal the cards
    </Button>
  );

  return (
    <>
      <div className="dealCardsArea">
        <div className="actionAreaCallout">
          <DealerPromptText />
        </div>
        <div className="startAreaButtons buttonContainer">
          <ChangeGameButton />
          <DealTheCardsButton />
        </div>
      </div>
    </>
  );
};

export const StartArea = () => {
  const { currentModalName, showModal } = useModal();

  const { isPreviousGameContinued } = useSelector(
    selectIsPreviousGameContinued
  );

  useEffect(() => {
    if (!currentModalName && !isPreviousGameContinued) {
      showModal({ name: ModalName.StartGameModal });
    }
  }, [currentModalName, isPreviousGameContinued, showModal]);

  if (isPreviousGameContinued) {
    return <ContinueGameArea />;
  } else {
    return <>Please fill out the game options above</>;
  }
};
