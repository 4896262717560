/**
 * GameTypeEntry.tsx
 * A single game in the "dealer choose game" prompt.
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Game } from "poker-cows-common";
import { getPrettyGameType } from "../../../utils/GameInfo";
import {
  selectGame,
  selectGameTypeActions,
} from "../../../logic/prompts/slice";
import "./GameTypeEntry.css";
import { selectGameType } from "../../../logic/table/slice";
import { selectGameUUID, selectPrefs } from "../../../logic/gameInstance/slice";
import { useModal } from "../Modal/hooks/useModal";
import { ModalName } from "../Modal/ModalConfig";

interface Props {
  id: string;
  gameType: Game;
  isRulePage: boolean;
}

/**
 * CurrentGameRibbon
 * Component used to show yellow coloured ribbon on current game
 */
const CurrentGameRibbon = () => <div className="gameTypeRibbon">CURRENT</div>;

const GameTypeEntry = (props: Props) => {
  const { showModal } = useModal();

  const dispatch = useDispatch();
  const { gameType } = useSelector(selectGameType);
  const { gameTypeActions } = useSelector(selectGameTypeActions);
  const { gameUUID } = useSelector(selectGameUUID);
  const { prefs } = useSelector(selectPrefs);

  const isCurrentGame =
    props.isRulePage && gameUUID && gameType === props.gameType;

  function updateGameRules() {
    showModal({
      name: ModalName.CurrentGameRulesModal,
      additionalProps: {
        selectedGame: props.gameType,
        selectedGameFlavor: isCurrentGame ? prefs?.gameFlavor : null,
      },
    });
  }

  function updateGameSelection() {
    const index = gameTypeActions.findIndex(
      (action) => action.data?.gameType === props.gameType
    );

    if (index < 0) {
      return;
    }

    dispatch(selectGame(index));
  }

  return (
    <div key={props.id} data-testid={props.id} className="gameSelectEntryBox">
      <div
        className="gameSelectEntry"
        onClick={() => {
          if (props.isRulePage) {
            updateGameRules();
            return;
          }
          updateGameSelection();
        }}
      >
        <>
          {isCurrentGame && <CurrentGameRibbon />}
          {getPrettyGameType(props.gameType)}
        </>
      </div>
    </div>
  );
};

export default GameTypeEntry;
