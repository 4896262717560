/**
 * This component is used to render the account page. It is a tabbed view.
 */

import React from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { Link, useHistory, useLocation } from "react-router-dom";
import Button from "../../components/Button";
import { AccountPageProfileTab } from "./AccountPageProfileTab";
import { AccountPageBillingTab } from "./AccountPageBillingTab";
import { MY_ACCOUNT_HEADER } from "../../../test-identifiers";

import styles from "./Account.module.css";
import { freeTrialIsOver } from "../../../utils/FreeTrial";
import JoinGameWidget from "../../components/JoinGameWidget";
import classNames from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import userImage from "../../../static/graphics/User.svg";

export const profilePagePath = "/account/profile";
export const billingPagePath = "/account/billing";

const profileTabId = "account-profile-tab";
const billingTabId = "account-billing-tab";

export const Account = () => {
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { logout } = useAuth0();
  const defaultTab = pathname === profilePagePath ? profileTabId : billingTabId;
  const url = freeTrialIsOver() ? "/payment-plan?host=true" : "/begin-session";

  return (
    <main className={classNames("accountPage", styles.accountPage)}>
      <section className={styles.headerPanel}>
        <a href="https://pokercows.com/" className={styles.pokerCowLogo}></a>
        <Button
          variant="black"
          className={styles.logoutButton}
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          <img
            src={userImage as unknown as string}
            alt="user"
            className={styles.userButtonIcon}
          />
          Logout
        </Button>
      </section>

      <section className={styles.sidePanel}>
        <Link to={url} className={styles.linkHostButton}>
          <Button variant="red" className={styles.hostButton}>
            HOST A GAME
          </Button>
        </Link>
        <span className={styles.sidePanelDivider}>OR</span>
        <JoinGameWidget />
      </section>

      <Tabs.Root
        className={styles.elementWithModalAppearance}
        defaultValue={defaultTab}
        onValueChange={(val: string) => {
          const updatedPathname =
            val === profileTabId ? profilePagePath : billingPagePath;

          push(updatedPathname);
        }}
      >
        <div>
          <div className={styles.tabsListAndHeader}>
            <div className={styles.header}>
              <h2 data-testid={MY_ACCOUNT_HEADER}>My Account</h2>
              <p>Player ID: 123456789</p>
            </div>

            <Tabs.List className={styles.tabsList}>
              <Tabs.Trigger asChild value={profileTabId}>
                <Button variant="gray">YOUR PROFILE</Button>
              </Tabs.Trigger>

              <Tabs.Trigger asChild value={billingTabId}>
                <Button variant="gray">BILLING & SUBSCRIPTION</Button>
              </Tabs.Trigger>
            </Tabs.List>
          </div>

          <Tabs.Content asChild value={profileTabId}>
            <AccountPageProfileTab />
          </Tabs.Content>

          <Tabs.Content asChild value={billingTabId}>
            <AccountPageBillingTab />
          </Tabs.Content>
        </div>

        <Button
          variant="base"
          onClick={() => push({ pathname: "/" })}
          className={styles.homeButton}
        >
          Back to Home
        </Button>
      </Tabs.Root>
    </main>
  );
};
