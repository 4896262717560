/**
 * MasterStartup.tsx
 *
 * this is a screen that shows upon the first time ever creating a table.
 * it's the first time the Initial Buy-in, Betting Structure (ante, min, max), and Chip Denominations settings are set.
 */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PrefInterface } from "poker-cows-common";
import { selectNextPrefs } from "../../../logic/table/slice";
import { initializeTable } from "../../../logic/prompts/slice";
import { RulesRow } from "../Settings/RulesPages/RulesRow";
import SpreadLimitSetup from "./MasterStartupComponents/SpreadLimitSetup";
import { InitialBuyInContent } from "../Settings/InitialSetup/InitialBuyInContent";
import { ChipDenomSetupContent } from "./MasterStartupComponents/ChipDenomSetupContent";
import { InitialBuyInTitle } from "./MasterStartupComponents/InitialBuyInTitle";
import { SpreadLimitSetupTitle } from "./MasterStartupComponents/SpreadLimitSetupTitle";
import { ChipDenomTitle } from "./MasterStartupComponents/ChipDenomTitle";
import { betSettingsInvariant } from "../../../utils/MoneyFormat";
import { NoLimitSetupTitle } from "./MasterStartupComponents/NoLimitSetupTitle";
import NoLimitSetup from "./MasterStartupComponents/NoLimitSetup";

import { ModalName } from "../Modal/ModalConfig";
import { useModal } from "../Modal/hooks/useModal";
import { ModalProps } from "../Modal/types/modalTypes";
import Button from "../Button";

import "../Settings/Settings.css";
import { MASTER_STARTUP_BUTTON } from "../../../test-identifiers";
import { LowMethodSetupTitle } from "./MasterStartupComponents/LowMethodSetupTitle";
import LowMethodSetup from "./MasterStartupComponents/LowMethodSetup";

export const MasterStartupContents = (props: ModalProps) => {
  const { nextPrefs } = useSelector(selectNextPrefs);
  const [newPrefs, setNewPrefs] = useState<Partial<PrefInterface>>(
    nextPrefs ?? {}
  );
  const dispatch = useDispatch();

  // set newPrefs, and tableInitialized to true so we know the masterStartup process has been triggered and dont show this screen again.
  useEffect(() => {
    setNewPrefs({
      ...newPrefs,
      tableInitialized: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(nextPrefs)]);

  // this runs when one of the dropdowns is changed and sets the new preferences
  const onUpdateInitialSettingsByPref = (
    prefsName: string,
    prefsValue: string
  ) => {
    betSettingsInvariant(
      prefsName,
      prefsValue,
      newPrefs,
      (updatedPrefs) => {
        setNewPrefs(updatedPrefs);
      },
      true /** Is Initialization */
    );
  };

  const onUpdatePrefs = (prefsName: string, prefsValue: string) => {
    setNewPrefs({
      ...newPrefs,
      [prefsName]: prefsValue,
    });
  };

  // this runs when the "start game" button is clicked, it's when the prefs are officially changed in state
  function onSavePrefsClick() {
    // TODO: Unsafe and could be replaced by something like https://github.com/davidmarkclements/fast-safe-stringify
    dispatch(initializeTable(JSON.stringify(newPrefs).replace(" ", "")));
  }

  return (
    <div className="masterContainer">
      <div className="masterStartupContainer">
        <div className="tableRulesWrapper">
          <RulesRow
            className="section"
            title={<InitialBuyInTitle />}
            content={
              <InitialBuyInContent
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<SpreadLimitSetupTitle />}
            content={
              <SpreadLimitSetup
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="chipDenomination section"
            title={<ChipDenomTitle />}
            content={
              <ChipDenomSetupContent
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<LowMethodSetupTitle />}
            content={
              <LowMethodSetup
                startupPrefs={newPrefs}
                onUpdateInitialSettingsByPref={onUpdatePrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
          <RulesRow
            className="section"
            title={<NoLimitSetupTitle />}
            content={
              <NoLimitSetup
                onUpdateInitialSettingsByPref={onUpdateInitialSettingsByPref}
                startupPrefs={newPrefs}
              />
            }
            isTitleBold={true}
            isContentBigger={true}
          />
        </div>
      </div>
      <footer className="modalFooter greenBackground">
        <Button
          data-testid={MASTER_STARTUP_BUTTON}
          onClick={onSavePrefsClick}
          className="modalButtonPrimary"
        >
          CONFIRM AND CONTINUE
        </Button>
      </footer>
    </div>
  );
};

export const MasterStartup = () => {
  const { currentModalName, showModal } = useModal();

  useEffect(() => {
    if (!currentModalName) {
      showModal({
        name: ModalName.MasterStartupModal,
      });
    }
  }, [currentModalName, showModal]);

  return (
    <span className="actionAreaCallout">
      PLEASE SELECT THE INITIAL
      <br /> TABLE SETTINGS ABOVE
    </span>
  );
};
