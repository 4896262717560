/**
 * StartBotTable.tsx
 *
 * Start a new table to play with bots (a table with one user player and four bot players).
 */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/Loading";
import { createTable } from "../../../logic/table/thunks";
import { selectTableCode } from "../../../logic/table/slice";
import { selectLocalPlayerFreeTrialStatus } from "../../../logic/localPlayer/slice";
import { useModal } from "../../components/Modal/hooks/useModal";
import { ModalName } from "../../components/Modal/ModalConfig";
import { SessionKeys, setSession } from "../../../utils/SessionSettings";

export const StartBotTable = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const { tableCode } = useSelector(selectTableCode);
  const { freeTrialStatus } = useSelector(selectLocalPlayerFreeTrialStatus);

  // create bot table after received details of the local player
  useEffect(() => {
    setSession(SessionKeys.isBotTable, "true");

    dispatch(createTable({ name: "Bot Table", isBotTable: true }));
  }, [dispatch]);

  // trigger detail when we have table code
  useEffect(() => {
    if (freeTrialStatus?.started) {
      history.push(`bot/table/${tableCode}`);
      return;
    }

    if (tableCode) {
      showModal({
        name: ModalName.WelcomeModal,
        requiresBackendVerification: false,
        additionalProps: {
          isBotTable: true,
        },
      });
    }
  }, [tableCode]);

  return <Loading />;
};
