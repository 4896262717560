/**
 * Welcome.tsx
 * This component used to display welcome message to the player with some instructions
 */
import React from "react";
import styles from "./Welcome.module.css";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useSelector } from "react-redux";
import { ModalProps } from "../Modal/types/modalTypes";
import Button from "../Button";
import { selectTableCode } from "../../../logic/table/slice";

interface IntructionProps {
  title: string;
  description: string;
  forBotOnly?: boolean;
}

const instructions: IntructionProps[] = [
  {
    title: "15 Second Table Set Up",
    description:
      "Set the initial buy-ins, antes, min bets, max bets, chip values and optional blinds.",
  },
  {
    title: "Select Games & Options",
    description:
      "Select one of 16 games and add options like high-low split pots, roll your own and wild cards.",
  },
  {
    title: "Play Against Bots",
    description:
      "They're not the smartest bots, but you'll get a feel for all the games and features of PokerCows! AI assisted bots coming soon.",
    forBotOnly: true,
  },
  {
    title: "Host Controls & Banking Features",
    description:
      "Explore the numerous host controls and banking features like playing at home.",
  },
];

export const Welcome = (props: ModalProps) => {
  const { tableCode } = useSelector(selectTableCode);

  const goToTable = () => {
    let url = "/table/" + tableCode;

    if (props.additionalProps?.isBotTable) {
      url = "/bot" + url;
    }

    window.location.href = url;
  };

  const Instruction = ({ instruction }: { instruction: IntructionProps }) => {
    if (instruction.forBotOnly && !props.additionalProps?.isBotTable) {
      return null;
    }

    return (
      <div key={instruction.title} className={styles.instruction}>
        <FontAwesomeIcon className={styles.icon} icon={faCheck as IconProp} />
        <div className={styles.instructionContent}>
          <div className={styles.title}>{instruction.title}</div>
          <div className={styles.description}>{instruction.description}</div>
        </div>
      </div>
    );
  };

  const Instructions = () => (
    <div className={styles.instructions}>
      {instructions.map((instruction: IntructionProps) => (
        <Instruction instruction={instruction} />
      ))}
    </div>
  );

  return (
    <div className={styles.welcome}>
      <div className={styles.header}>
        <div>Welcome to Pokercows !</div>
        <div className={styles.headerDescription}>
          Enjoy 30 days of FREE play on us.
          <br />
          No credit card, no commitment, no bullsh*t!
        </div>
      </div>
      <div className={classNames("section", styles.content)}>
        <div className={styles.caption}>Checkout these great features:</div>
        <Instructions />
      </div>
      <Button variant="green" onClick={goToTable}>
        CONTINUE
      </Button>
    </div>
  );
};
