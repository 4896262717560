import { io } from "socket.io-client";
import axios from "axios";
import { PlayerIdType } from "poker-cows-common";
import { SessionKeys, getSession } from "../utils/SessionSettings";

const url = process.env.REACT_APP_BACKEND_URL || "http://localhost:3002";
const socketUrl = process.env.REACT_APP_SOCKET_URL || "http://localhost:3002";

let storedLocalPlayerId: PlayerIdType | null = null;

export const setLocalPlayerId = (playerId: PlayerIdType) => {
  storedLocalPlayerId = playerId;
};
// TODO(@Nerdsie): If endpoint starts with /, strip it ( to avoid // )
function endpointToURL(endpoint: string, tableGroup: string) {
  return tableGroup ? `${url}/${tableGroup}/${endpoint}` : `${url}/${endpoint}`;
}

// Sockets
export const getWebsocket = (
  tableId: string,
  tableGroup: string,
  localPlayerId: string
) => {
  const path = tableGroup ? `/${tableGroup}` : "";
  return io(socketUrl + "/", {
    path: `${path}/websocket/`,
    auth: {
      tableId: tableId,
      userId: localPlayerId ?? storedLocalPlayerId,
    },
  });
};

// GET requests
export const getEndpoint = (endpoint: string, tableGroup: string) =>
  get(endpointToURL(endpoint, tableGroup));

async function get(url: string) {
  const requestOptions = { headers: { "Access-Control-Allow-Origin": "*" } };

  return axios
    .get(url, requestOptions)
    .then((response) => response.data)
    .catch((error) => error);
}

// POST requests
export const postEndpoint = (
  endpoint: string,
  tableGroup: string,
  body: any
) => {
  return post(endpointToURL(endpoint, tableGroup), {
    ...body,
    userId:
      body.localPlayerId ??
      storedLocalPlayerId ??
      getSession(SessionKeys.localPlayerId),
  });
};

export async function post(url: string, body: any) {
  const requestOptions = { headers: { "content-type": "application/json" } };

  // This returns a promise of a json response, should modify to somehow return actual
  // response instead of just the json
  // (so that we can handle 404 or other non-json responses)

  // TODO Vijay: we could just return the actual response consistently like so, instead of just the json data:
  //     return axios.post(url, body, requestOptions)
  //         .then(response => response)
  //         .catch(error => error.response);
  // but there is already code all over that assumes just a json data is returned,
  // so for now im going to just insert the response status code into the assumed json, so we can at least use that.
  return axios
    .post(url, body, requestOptions)
    .then((response) => {
      return { ...response.data, status: response.status };
    })
    .catch((error) => error.response);
}
