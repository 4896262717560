/**
 * DownIconLarge.tsx
 *
 * The larger icon is used for dropdown arrow next to chip buttons in table setup.
 */
import React from "react";

export const DownIconLarge = ({
  width = 28,
  height = 28,
  color = "white",
  className = ""
}: {
  width?: number
  height?: number
  color?: string;
  className: string;
}) => {
  return (
    <span className={className}>
        <svg width={width} height={height} viewBox={`0 0 ${width + 2} ${height + 2}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" y="1" width={width} height={height} rx="3" fill="#272D2D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.61244 10.9413C7.98437 10.5752 8.58738 10.5752 8.95931 10.9413L14.0002 15.9034L19.041 10.9413C19.4129 10.5752 20.016 10.5752 20.3879 10.9413C20.7598 11.3075 20.7598 11.901 20.3879 12.2672L14.6736 17.8922C14.3017 18.2583 13.6987 18.2583 13.3267 17.8922L7.61244 12.2672C7.24051 11.901 7.24051 11.3075 7.61244 10.9413Z" fill="white"/>
        <rect x="1" y="1" width={width} height={height} rx="3" stroke="#D5D5D7" stroke-width="2"/>
        </svg>
    </span>
  );
};
