/**
 * PaymentAPIHook.tsx
 *
 * Adds an Axios hook that redirects payment responses made to Express API.
 *
 */

import axios from "axios";

const PaymentAPIHook = ({ children }: { children: JSX.Element }) => {
  // Response interceptor for API calls
  axios.interceptors.response.use(
    response => {
      if (response.data?.userPaymentRequired) {
        window.location.href = "/payment-plan";
      }
      return Promise.resolve(response);
    },
    error => Promise.reject(error)
  );

  return children;
};

export default PaymentAPIHook;
